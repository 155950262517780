<!--评论-->
<template>
    <div class="body">
        <div class="comment-wrapper">
            <div class="business-wrapper">
                <img class="business-head" :src="'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/' + initInfo.logo">
                <div class="business-name">{{initInfo.gooods_name}} </div>
            </div>
            <div class="comment-star-wrapper">
                <div class="comment-star-text">服务</div>
                <van-rate v-model="starSev" />
            </div>
            <div class="comment-star-wrapper">
                <div class="comment-star-text">菜品</div>
                <van-rate v-model="starFood" />
            </div>
            <div class="comment-star-wrapper">
                <div class="comment-star-text">环境</div>
                <van-rate v-model="starEnv" />
            </div>
            <textarea maxlength="128" class="comment-text" rows="8" placeholder="请填写评价内容" v-model="comment"></textarea>
            <div class="comment-img-wrapper">
                <van-uploader v-model="fileList" multiple max-count="6" :max-size="500 * 1024" upload-text="图片" @oversize="overSizeTip"/>
            </div>
        </div>
        <div class="bottom-button">
            <van-button primary type="danger" size="large" @click="submitComment">确认提交</van-button>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'

  export default {
    name: 'Comment',
    mixins: [globalConfig],
    data () {
      return {
        id: 0,
        initInfo: [],
        starSev: 0,
        starFood: 0,
        starEnv: 0,
        comment: '',
        fileList: [
          // { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
          // // Uploader 根据文件后缀来判断是否为图片文件
          // // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
          // { url: 'https://cloud-image', isImage: true }
        ]
      }
    },
    methods: {
      submitComment: function () {
        if (this.starSev && this.starFood && this.starEnv && this.comment) {
          const imgArr = this.fileList.map(function (value, index, array) {
            return value.content
          })
          const that = this
          this.axios.post(this.apiUrl + 'mall/comment/submitComment', {
            imgArr: imgArr,
            comment: that.comment,
            id: that.id,
            stars_sev: that.starSev,
            stars_food: that.starFood,
            stars_env: that.starEnv
          }).then((response) => {
            const data = response.data
            if (data.status === 1) {
              that.$toast({
                message: '感谢您的评论',
                onClose: function () {
                  that.$router.push('/commentList')
                }
              })
            } else {
              that.$toast(data.msg)
            }
          })
        } else {
          this.$toast('请填写完整评论')
        }
      },
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'mall/init/comment', {
          id: that.id
        }).then((response) => {
          const data = response.data
          that.initInfo = data.info
        })
      },
      overSizeTip: function () {
        this.$toast('文件大小不能超过500kb')
      }
    },
    created () {
      this.id = this.$route.params.id
      this.init()
    }
  }
</script>

<style scoped>
    .body {
        position: absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#eee;
    }
    .comment-wrapper{
        background-color: #ffffff;
        padding: 0.6rem;
    }
    .business-wrapper{
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        padding: 0.5rem 1rem;
    }
    .business-head{
        width:3rem;
        height: 3rem;
        border-radius: 5%;
        overflow: hidden;
        margin-right: 1rem;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        -webkit-align-items: center;
        -webkit-justify-content: center;
    }
    .business-name{
        width: 18rem;
        color: #2e2e2e;
        font-weight: bold;
        font-size: 1rem;
        letter-spacing: 0.1rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    .comment-star-wrapper{
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 0.9rem;
        color:#292929;
        padding: 0.2rem 1rem;
        margin-top: 0.7rem;
        box-sizing: border-box;
    }
    .comment-star-text {
        margin-right: 1rem;
        color: #000000;
        font-weight: 700;
    }
    .comment-text {
        display: block;
        width: 90%;
        margin: 1rem auto 0;
    }
    textarea{
        padding: 0.5rem;
        outline-style: none;
        color: #999;
        font-size: 1rem;
        border-style:none;
        background-color: #f3f3f3
    }
    textarea::-webkit-input-placeholder {
        color: #999;
        font-size: 1rem;
    }
    textarea:-moz-placeholder {
        color: #999;
        font-size: 1rem;
    }
    textarea::-moz-placeholder {
        color: #999;
        font-size: 1rem;
    }
    textarea:-ms-input-placeholder {
        color: #999;
        font-size: 1rem;
    }
    .comment-img-wrapper {
        width: 93%;
        margin: 1rem auto 0;
    }
    .bottom-button {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
    }
</style>
